#bottomBar {
	background-color: rgb(29, 27, 27);
	color: white;
	width: 100%;
	min-height: 150px;
	padding: 20px 0;
	text-align: center;
}

.footerLogo {
	width: 100px;
}
